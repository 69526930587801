import React, { useMemo } from 'react';

import { Link, Redirect } from 'react-router-dom';
import {
  Container,
  Logo,
  LogoContent,
  LogoOptions,
  HomeOption,
  RegulationOption,
  ImageContainer,
  MainImg,
} from './styles';

import {
  AiOutlineFileText,
  AiOutlineHome,
  AiOutlineArrowLeft,
  AiOutlineQuestionCircle,
  AiOutlineAlert,
} from 'react-icons/ai';

import { UserContainer } from './styles';

import { useAuth } from '../../hooks/auth';
import { useTeam } from '../../hooks/team';

import chicoLogo from '../../assets/img/containerLogo.png';

interface HeaderProps {
  selectedTab?: string;
  setTab?(tab: string): React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<HeaderProps> = ({
  selectedTab = 'home',
  setTab,
  children,
}) => {
  const { user, signOut } = useAuth();
  const { team } = useTeam();

  const defaultUserProfileImage = useMemo<string>(
    () =>
      'https://nextlevelimagesprofile.s3-sa-east-1.amazonaws.com/defaultUser.png',
    [],
  );

  const logoClick = () => {
    if (user && team) window.location.href = '/main';
    else window.location.href = '/';
  };

  return (
    <Container>
      <LogoContent>
        <LogoOptions>
          {user && team && (
            <div style={{ width: '85%' }}>
              <UserContainer>
                <ImageContainer
                  onClick={() => (window.location.href = '/edit')}
                >
                  <div className="main-profile-img">
                    <MainImg
                      src={
                        user.imageurl && user.imageurl !== ' '
                          ? user.imageurl
                          : defaultUserProfileImage
                      }
                      alt="user"
                    />
                  </div>
                </ImageContainer>
                <div
                  onClick={() => (window.location.href = '/edit')}
                  className="user-data-container"
                >
                  <h3>{user.nickname}</h3>
                </div>
                <Link to="/main">
                  <HomeOption tab={selectedTab}>
                    <AiOutlineHome />
                    Home
                  </HomeOption>
                </Link>
                <Link to="/">
                  <HomeOption tab={selectedTab} onClick={signOut}>
                    <AiOutlineArrowLeft />
                    Sair
                  </HomeOption>
                </Link>
              </UserContainer>
            </div>
          )}
          {!user && (
            <>
              <Link
                onClick={() => {
                  setTab && setTab('home');
                }}
                to="/"
              >
                <RegulationOption tab={selectedTab}>
                  <AiOutlineHome />
                  Home
                </RegulationOption>
              </Link>
              <Link
                onClick={() => {
                  setTab && setTab('works');
                  localStorage.removeItem('@Challenge:firstAccess');

                  if (window.location.pathname == '/') {
                    window.location.reload();
                  } else {
                    return <Redirect to="/" />;
                  }
                }}
                to="/"
              >
                <RegulationOption tab={selectedTab}>
                  <AiOutlineQuestionCircle />
                  Como Funciona
                </RegulationOption>
              </Link>
              <Link
                onClick={() => {
                  setTab && setTab('faq');
                }}
                to="/faq"
              >
                <RegulationOption tab={selectedTab}>
                  <AiOutlineAlert />
                  FAQ
                </RegulationOption>
              </Link>
              <Link
                onClick={() => {
                  setTab && setTab('regulamento');
                }}
                to="/regulamento"
              >
                <RegulationOption tab={selectedTab}>
                  <AiOutlineFileText />
                  Regulamento
                </RegulationOption>
              </Link>
            </>
          )}
        </LogoOptions>
      </LogoContent>
      <Logo
        src={chicoLogo}
        alt="chicologo"
        onClick={logoClick}
        style={{ zIndex: 100 }}
      />
    </Container>
  );
};

export default Header;
