import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { FiSave } from 'react-icons/fi';
import * as Yup from 'yup';
import ReactLoading from 'react-loading';
import Axios from 'axios';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import ImageUpload from '../../components/ImageUpload';

import getValidationErrors from '../../utils/getValidationErrors';

import {
  PageGame,
  TContainer,
  CircleContent,
  StyledButton,
  PageWrapper,
  FormContainer,
  FormHeaderTitle,
  StyledInput,
  ImageContainer,
  ImageComponents,
  MainImg,
  GridCustom,
  GridCustomItem,
} from './styles';

import Header from '../../components/Header';

interface DataFormInfo {
  userid: string;
  teamid?: string;
  useremail: string;
  userfullname: string;
  usernickname: string;
  userpassword: string;
  imageurl: string;
  phone: string;
  city: string;
  school: string;
  level: string;
}

const initialInputValues: DataFormInfo = {
  userid: '',
  teamid: '',
  usernickname: '',
  useremail: '',
  userfullname: '',
  userpassword: '',
  city: '',
  school: '',
  phone: '',
  level: '',
  imageurl: '',
};

const Accept: React.FC = () => {
  const [isLogging, setIsLogging] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const { user, updateUser } = useAuth();
  const [inputValues, setInputValues] = useState(initialInputValues);
  const { addToast } = useToast();
  const [userImgUrl, setUserImgUrl] = useState('');
  const formRef = useRef<FormHandles>(null);
  const iconStyle = {
    marginLeft: '-10px',
    marginRight: '10px',
  } as React.CSSProperties;

  const defaultUserProfileImage = useMemo<string>(
    () =>
      'https://nextlevelimagesprofile.s3-sa-east-1.amazonaws.com/defaultUser.png',
    [],
  );

  const handleSubmit = useCallback(
    async (data: DataFormInfo) => {
      setIsLogging(true);
      setIsEnabled(false);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          useremail: Yup.string()
            .email('Insira um email válido')
            .required('Email obrigatório'),
          userfullname: Yup.string().required('Nome completo obrigatório'),
          usernickname: Yup.string().required('Nome do usuário obrigatório'),
          userpassword: Yup.string().required('Senha obrigatória'),
          phone: Yup.string().required('Telefone obrigatório'),
          city: Yup.string().required('Cidade obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await Axios.post(
          `https://j1hjd787mc.execute-api.sa-east-1.amazonaws.com/prod/user/edit`,
          {
            userid: user.userid,
            userimageurl: userImgUrl.split('base64,')[1],
            userfullname: data.userfullname,
            useremail: data.useremail,
            userpassword: data.userpassword,
            usernickname: data.usernickname,
            phone: data.phone,
            city: data.city,
            school: data.school,
            level: data.level,
          },
        ).then((res) => {
          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Informações salvas com sucesso!',
          });

          let updateUserParam = {
            city: res.data.city,
            email: res.data.email,
            fullname: res.data.fullname,
            imageurl: res.data.imageurl,
            level: res.data.level,
            nickname: res.data.nickname,
            password: res.data.password,
            phone: res.data.phone,
            school: res.data.school,
            teamid: res.data.teamid,
            userid: res.data.userid,
          };

          updateUser(updateUserParam);
        });

        setIsLogging(false);
        setIsEnabled(true);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops...',
          description: 'Algo deu errado, tente novamente mais tarde.',
        });

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      } finally {
        setIsLogging(false);
        setIsEnabled(true);
      }
    },
    [userImgUrl, user, inputValues],
  );

  const handleInputChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  useEffect(() => {
    setInputValues({
      ...inputValues,
      userid: user.userid,
      teamid: user.teamid,
      usernickname: user.nickname,
      useremail: user.email,
      userfullname: user.fullname,
      userpassword: user.password,
      city: user.city,
      school: user.school,
      phone: user.phone,
      level: user.level,
      imageurl: user.imageurl,
    });
  }, [user]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//code.jivosite.com/widget/OKHPleczFN';
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <PageGame>
      <Header />
      <script src="//code.jivosite.com/widget/OKHPleczFN" async />
      <TContainer>
        <PageWrapper>
          <FormContainer>
            <CircleContent>
              <Form ref={formRef} onSubmit={handleSubmit} className="form">
                <FormHeaderTitle>Editar Perfil</FormHeaderTitle>
                <GridCustom>
                  <ImageComponents>
                    <ImageContainer>
                      <div className="main-profile-img">
                        <MainImg
                          src={
                            userImgUrl &&
                            userImgUrl !== ' ' &&
                            userImgUrl !== undefined
                              ? userImgUrl
                              : user.imageurl !== ' '
                              ? user.imageurl
                              : defaultUserProfileImage
                          }
                        />
                      </div>
                    </ImageContainer>
                    <div className="imageUploader">
                      <ImageUpload setImgUrl={setUserImgUrl} />
                    </div>
                  </ImageComponents>

                  <GridCustomItem>
                    <div>
                      <StyledInput
                        name="usernickname"
                        placeholder="Nome de usuário"
                        value={inputValues.usernickname}
                        onChange={(e) => handleInputChanges(e)}
                      />
                      <StyledInput
                        name="useremail"
                        placeholder="Email"
                        value={inputValues.useremail}
                        onChange={(e) => handleInputChanges(e)}
                      />
                    </div>
                    <div>
                      <StyledInput
                        name="userfullname"
                        placeholder="Nome completo"
                        value={inputValues.userfullname}
                        onChange={(e) => handleInputChanges(e)}
                      />
                      <StyledInput
                        name="userpassword"
                        type="password"
                        placeholder="Senha"
                        value={inputValues.userpassword}
                        onChange={(e) => handleInputChanges(e)}
                      />
                    </div>
                  </GridCustomItem>
                </GridCustom>

                <GridCustom>
                  <div>
                    <StyledInput
                      name="city"
                      placeholder="Cidade"
                      value={inputValues.city}
                      onChange={(e) => handleInputChanges(e)}
                    />
                    <StyledInput
                      name="school"
                      placeholder="Escola"
                      value={
                        inputValues.school !== ' ' ? inputValues.school : ''
                      }
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                  <div>
                    <StyledInput
                      name="phone"
                      placeholder="Telefone"
                      value={inputValues.phone}
                      onChange={(e) => handleInputChanges(e)}
                    />
                    <StyledInput
                      name="level"
                      placeholder="Série (Ex.: 9° ano)"
                      value={inputValues.level !== ' ' ? inputValues.level : ''}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </GridCustom>

                <div
                  style={{
                    gridColumn: 'span 2',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <StyledButton enabled={isEnabled} type="submit">
                    {isLogging ? (
                      <ReactLoading />
                    ) : (
                      <>
                        <FiSave style={iconStyle} />
                        Salvar
                      </>
                    )}
                  </StyledButton>
                </div>
              </Form>
            </CircleContent>
          </FormContainer>
        </PageWrapper>
      </TContainer>
    </PageGame>
  );
};

export default Accept;
