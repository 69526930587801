import React from 'react';

interface CheckboxProps {
  textContent?: string;
  inputName?: string;
  onClick?: any;
}

const Checkbox: React.FC<CheckboxProps> = ({
  textContent,
  inputName,
  onClick,
}) => {
  return (
    <label className="container-checkbox">
      {textContent}
      {/* <input type="checkbox" checked="checked"> */}
      <input onClick={onClick} name={inputName} type="checkbox" />
      <span className="checkmark"></span>
    </label>
  );
};

export default Checkbox;
