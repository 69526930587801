/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-equals-spacing */
/* eslint-disable react/jsx-indent */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactLoading from 'react-loading';

import PaymentCard from '../../components/PaymentCard';
import PaymentBol from '../../components/PaymentBol';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';

import Axios from 'axios';

import { StyledButtonBack } from '../../styles/global';
import {
  PageGame,
  TContainer,
  CircleContent,
  StyledButton,
  PageWrapper,
  ButtonsContainer,
  GridCustom,
  StyledInput,
} from './styles';
import Header from '../../components/Header';
import { LogoOptions, LogoContent } from './styles';

import { FiCreditCard, FiDollarSign } from 'react-icons/fi';
import { AiOutlineArrowLeft, AiOutlinePercentage } from 'react-icons/ai';

import { useToast } from '../../hooks/toast';
import { usePay } from '../../hooks/payment';

const Payment: React.FC = () => {
  const { currentlyValue, setCurrentlyValue, setIsCoupon } = usePay();
  const [isCard, setIsCard] = useState(false);
  const [isBol, setIsBol] = useState(false);
  const [pay, setPay] = useState('');
  const formRef = useRef<FormHandles>(null);
  const [isEnabled, setIsEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();

  const iconStyle = {
    marginLeft: '-10px',
    marginRight: '10px',
  } as React.CSSProperties;

  const PayCard = () => {
    setPay('login');
    setIsCard(true);
  };

  const PayBol = () => {
    setPay('login');
    setIsBol(true);
  };

  const goBack = () => {
    if (!isCard && !isBol) window.history.back();
    else if (isCard && !isBol) {
      setPay('false');
      setIsCard(false);
    } else if (!isCard && isBol) {
      setPay('false');
      setIsBol(false);
    }
  };

  const returnPayment = () => {
    if (isCard) {
      return 'card';
    } else if (isBol) {
      return 'pix';
    } else {
      return 'payment';
    }
  };

  const handleSubmit = useCallback(async (data: any) => {
    setIsEnabled(false);
    setIsLoading(true);

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        coupon: Yup.string().required('Cupom obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await Axios.get(
        `https://j1hjd787mc.execute-api.sa-east-1.amazonaws.com/prod/payment/validatecoupon?couponid=${data.coupon}`,
      ).then((res) => {
        if (res.data.finaldiscont) {
          setCurrentlyValue(res.data.finalvalue);
          setIsCoupon(data.coupon);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: `Cupom de ${res.data.finaldiscont} aplicado.`,
          });
        } else {
          addToast({
            type: 'info',
            title: 'Ops...',
            description: 'Cupom inválido!',
          });
        }
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        addToast({
          type: 'error',
          title: 'Ops...',
          description: 'Algo deu errado! Tente novamente por favor.',
        });
      }
    } finally {
      formRef.current?.reset();
      setIsEnabled(true);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//code.jivosite.com/widget/OKHPleczFN';
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <PageGame>
      <Header />
      <LogoContent style={{ position: 'relative' }}>
        <LogoOptions>
          <StyledButtonBack
            onClick={goBack}
            type="button"
            style={{ position: 'absolute', top: '0' }}
          >
            <AiOutlineArrowLeft />
            Voltar
          </StyledButtonBack>
          <script src="//code.jivosite.com/widget/OKHPleczFN" async />
          <TContainer>
            <br />
            <PageWrapper>
              <CircleContent payment={returnPayment()}>
                {!isCard && !isBol && (
                  <div>
                    <p className="amount">
                      Valor da Inscrição do time:
                      <strong> R${currentlyValue}</strong>
                    </p>
                    <Form
                      ref={formRef}
                      onSubmit={handleSubmit}
                      className="form"
                    >
                      <GridCustom>
                        <div>
                          <StyledInput
                            name="coupon"
                            placeholder="Cupom de desconto"
                          />
                        </div>
                        <div>
                          <StyledButton
                            enabled={isEnabled}
                            type="submit"
                            countdownOver={true}
                          >
                            {isLoading ? (
                              <ReactLoading />
                            ) : (
                              <>
                                <AiOutlinePercentage style={iconStyle} />
                                Aplicar
                              </>
                            )}
                          </StyledButton>
                        </div>
                      </GridCustom>
                    </Form>
                    <p>Selecione uma das formas de pagamento</p>
                  </div>
                )}
                {pay !== 'login' ? (
                  <ButtonsContainer>

                    <StyledButton onClick={PayBol} countdownOver={true}>
                      <FiDollarSign style={iconStyle} />
                      Pix
                    </StyledButton>
                  </ButtonsContainer>
                ) : (
                  (isCard && (
                    <TContainer>
                      <PaymentCard />
                    </TContainer>
                  )) ||
                  (isBol && (
                    <TContainer>
                      <PaymentBol />
                    </TContainer>
                  ))
                )}
              </CircleContent>
            </PageWrapper>
          </TContainer>
        </LogoOptions>
      </LogoContent>
    </PageGame>
  );
};

export default Payment;
