import React, { createContext, useCallback, useState, useContext } from 'react';
import Axios from 'axios';
import { useToast } from '../hooks/toast';

interface User {
  email: string;
  password: string;
  nickname: string;
  fullname: string;
  phone: string;
  city: string;
  school: string;
  level: string;
  userid: string;
  imageurl: string;
  teamimage?: string;
  teamid: string;
}

interface AuthContextData {
  user: User;
  senha?: string;
  signIn(loginInfo: Login): Promise<void>;
  signOut(): void;
  requestPasswordEmail: any;
  resetPassword: any;
  updateUser(user: User): void;
}

interface Login {
  email: string;
  password: string;
}

interface UserLoginData {
  user: User;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const [data, setData] = useState<UserLoginData>(() => {
    const token = localStorage.getItem('@Challenge:token');
    const user = localStorage.getItem('@Challenge:user');

    if (user) {
      //Axios.defaults.headers.authorization = `Bearer ${token}`;
      return {
        token,
        user: JSON.parse(user),
      };
    }

    return {} as UserLoginData;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await Axios.post(
      `https://j1hjd787mc.execute-api.sa-east-1.amazonaws.com/prod/login`,
      {
        email: email,
        password: password,
      },
    );

    const { user } = response.data;

    Object.assign(user);

    if (user !== undefined) {
      localStorage.setItem('@Challenge:user', JSON.stringify(user));
    }

    setData({
      user,
    });
    return user.teamid;
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Challenge:token');
    localStorage.removeItem('@Challenge:user');
    localStorage.removeItem('@Challenge:team');

    setData({} as UserLoginData);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@Challenge:user', JSON.stringify(user));

      setData({
        user,
      });
    },
    [setData],
  );

  const requestPasswordEmail = async (identification: any) => {
    try {
      const response = await Axios.post<any>(
        `https://j1hjd787mc.execute-api.sa-east-1.amazonaws.com/prod/user/forgotpassword/token`,
        {
          identification,
        },
      );

      return response;
    } catch (err) {
      console.log(err);
      addToast({
        type: 'error',
        title: 'Erro na autenticação',
        description: 'Ops... parece que algo deu errado com suas credenciais',
      });
    }
  };

  const resetPassword = async (token: any, password: any) => {
    try {
      const response = await Axios.post<any>(
        `https://j1hjd787mc.execute-api.sa-east-1.amazonaws.com/prod/user/forgotpassword/edit`,
        {
          token,
          password,
        },
      );

      return response;
    } catch (err) {
      console.log(err);
      addToast({
        type: 'error',
        title: 'Erro na autenticação',
        description: 'Ops... parece que algo deu errado com suas credenciais',
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateUser,
        requestPasswordEmail,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}
