import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { DefaultColors } from '../../styles/global';

import { loadFromTransparent, bringFromLeft } from './animations';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Input from '../../components/Input';
import RulesBackground from '../../assets/img/rulesConsole.png';

interface Test {
  load?: boolean;
}

interface StyledButtonProps {
  countdownOver?: boolean;
}

export const PageGame = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const TContainer = styled.div`
  display: flex;
  align-items: flex-start;

  flex-direction: column;

  /* min-height: 100vh;
  height: 100vh; */
  /* width: 1170px; */

  background-image: url(${RulesBackground});
  background-repeat: no-repeat;
  background-size: 690px;
  background-position-x: center;
  background-position-y: center;


  animation: ${loadFromTransparent} 1.4s;

  @media (max-width: 820px) {
    background: none;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  min-height: 80vh;
`;

export const CircleContent = styled(Card)<Test>`
  width: 550px;
  height: 500px;

  display: flex;
  justify-content: center;
  position: relative;

  /* margin-left: 22em; */

  @media (max-width: 820px) {
    height: auto;
  }

  ${(props) =>
    props.load
    ? css`
        animation: ${bringFromLeft} 1s;
      `
    : css`
        animation: ${bringFromLeft} 1s;
      `}
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  /* margin-top: 10px; */

  text-align: center;

  @media (max-width: 820px) {
    box-sizing: border-box;
    background-image: none;
    background-color: white;
    border-radius: 10px;
    width: 90%;
    max-width: 350px;
    height: auto;
    margin: 0 auto !important;
    margin-bottom: 100px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9999999;
    
    border: 20px solid transparent;
    background: linear-gradient(to left, ${DefaultColors.primaryColor}, ${DefaultColors.primaryColor});

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -8px;
      border-radius: inherit;
      background: #fff;
    }
  }

  a {
    font-family: 'Poppins';
    text-decoration: none;
    color: #fff;

    transition: color 0.3s;

    &:hover {
      color: ${shade(0.4, '#fff')};
    }
  }

  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    
    @media (max-width: 820px) {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const FormHeaderTitle = styled.h2`
  grid-column: span 2;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
`;

export const Content = styled.p`
  width: 440px;

  text-align: center;
  margin-top: 2em;

  font-family: 'Poppins';
  font-size: 18px;

  color: #fff;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  a {
    text-decoration: none;
  }

  @media (max-width: 700px) {
    padding: 0 32px;
  }
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;

  font-size: 14px;
  border-radius: 220px;

  background: ${DefaultColors.secondColor};
  color: #fff;

  font-family: 'Poppins';

  &:hover {
    background: ${shade(0.4, DefaultColors.secondColor)};
  }

  ${(props) =>
    props.countdownOver &&
    css`
      width: 100%;
    `}
`;

export const StyledInput = styled(Input)`
  div {
    width: 100% !important;

  }
`;

export const ImageComponents = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  /* height: 22%; */

  margin-bottom: 2%;

  .imageUploader {
    display: flex;
    position:absolute;
    top: 22%;
    left: 66%;

    .fileUploader {
      .fileContainer {

        button {
          margin-left: -40px !important;
          margin-top: 7px !important;
          padding: 20px !important;
        }
      }
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;

  flex-shrink: 0;

  .main-profile-img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: solid 2px #dbdbdb;

    @media (max-height: 835px) {
      width: 50px;
      height: 50px;
    }
  }
`;

export const MainImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
`;

export const GridCustom = styled.div`
  grid-column: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 820px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

export const GridCustomItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 820px) {
    grid-template-columns: 1fr;
    gap: 10px;

  }
`;
