/*{ eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-indent */
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { FiLogOut, FiPlay } from 'react-icons/fi';
import ReactLoading from 'react-loading';
import { useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import Axios from 'axios';
import { useAuth } from '../../hooks/auth';
import { Message, useChat } from '../../hooks/chat';
import { useTeam } from '../../hooks/team';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';
import erro from '../../assets/img/erro.png';

import * as S from './styles';

import Alert from '../../components/Alert';
import Confirm from '../../components/Confirm';
import FinalComponent from '../../components/FinalComponent';
import Header from '../../components/Header';
import QuestionContent from '../../components/QuestionContent';
import Ranking from '../../components/Ranking';

interface Question {
  answer: string;
  answercharactercounter: string;
  hint: string;
  points: string;
  questionid: string;
  title: string;
  type: string;
  url: string;
}

interface nextquestion {
  nextQuestion: Question;
}

interface hint {
  hint: string;
}

interface AnswerQuestion {
  iscorrect: boolean;
  nextquestion: Question;
}

interface DataFormInfo {
  answer: string;
}

const Questionary: React.FC = () => {
  const { signOut, user } = useAuth();
  const { team } = useTeam();
  const { addMessage, clearMessages } = useChat();
  const { push } = useHistory();

  const [caracterCounter, setCaracterCounter] = useState(999);
  const [rememberAnswer, setRememberAnswer] = useState('');
  const [verifyPing, setVerifyPing] = useState('pong');

  const [passing, setIsPassing] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmTip, setConfirmTip] = useState(false);
  const [answering, setIsAnswering] = useState(false);
  const [reportError, setReportError] = useState(false);
  const [qId, setQId] = useState('');
  const [passToast, setPassToast] = useState('');

  const [wsResponse, setWsResponse] = useState('');

  const consoleRef = useRef<HTMLDivElement>(null);
  const rankingRef = useRef<HTMLDivElement>(null);
  const actionButtonsRef = useRef<HTMLDivElement>(null);

  /*const ENDPOINT_WS =
    team.category === 'Fundamental'
      ? (process.env.REACT_APP_FUND_WS as string)
      : (process.env.REACT_APP_PROD_WS as string);*/

  const ENDPOINT = `https://j1hjd787mc.execute-api.sa-east-1.amazonaws.com/prod`;
  const ENDPOINT_WS = `wss://admv564mu8.execute-api.sa-east-1.amazonaws.com/prod`;

  /*const ENDPOINT =
    team.category === 'Fundamental'
      ? (process.env.REACT_APP_FUND_API as string)
      : (process.env.REACT_APP_PROD_API as string);*/

  const { addToast } = useToast();
  const [question, setQuestion] = useState<Question>({
    answer: '',
    answercharactercounter: '',
    hint: '',
    points: '',
    questionid: '',
    title: '',
    type: '',
    url: '',
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const _listenerFunc = () => {
      const consolePositions = consoleRef?.current?.getBoundingClientRect();

      if (rankingRef.current && consolePositions && actionButtonsRef.current) {
        const targetX =
          window.scrollX !== 0 ? consolePositions.x : window.scrollX;

        let leftPosition = targetX + consolePositions.width * 0.15;
        let topPosition = consolePositions.y + consolePositions.height * 0.08;

        if (document.body.offsetWidth > 1400) {
          leftPosition = targetX + consolePositions.width * 0.2;
          topPosition = targetX + consolePositions.width * 0.1;
        }

        if (
          document.body.offsetWidth > 1200 &&
          document.body.offsetWidth < 1400
        ) {
          topPosition = consolePositions.y + consolePositions.height * 0.07;
        }

        if (document.body.offsetHeight <= 660) {
          console.log(document.body.offsetHeight);
          rankingRef.current.style.margin = `0px`;
          actionButtonsRef.current.style.margin = `-36px`;
          actionButtonsRef.current.style.marginTop = `7vh`;

          leftPosition = leftPosition - 40;
        }
        rankingRef.current.style.marginTop = `-3vh`;
        rankingRef.current.style.left = `${leftPosition}px`;
        rankingRef.current.style.top = `${topPosition}px`;
        rankingRef.current.style.display = 'block';
        let actionBtnPosition =
          consolePositions.x + consolePositions.width * 0.66;
        if (document.body.offsetWidth >= 1560) {
          actionBtnPosition =
            consolePositions.x + consolePositions.width * 0.67;
        }
        actionButtonsRef.current.style.left = `${actionBtnPosition}px`;
        actionButtonsRef.current.style.display = 'block';
      }
    };
    window.addEventListener('resize', _listenerFunc);

    timer = setTimeout(() => {
      _listenerFunc();
    }, 1000);

    return () => {
      window.removeEventListener('resize', _listenerFunc);
      clearTimeout(timer);
    };
  }, []);

  const formRef = useRef<FormHandles>(null);
  const sWs = useRef<WebSocket>();

  const ping = useCallback(async () => {
    await sWs.current?.send(
      JSON.stringify({
        action: 'onMessage',
        teamcategory: team.category,
        type: 'ping',
        userid: user.userid,
        teamid: user.teamid,
      }),
    );
  }, []);

  const getRanking = useCallback(() => {
    sWs.current?.send(
      JSON.stringify({
        action: 'onMessage',
        teamcategory: team.category,
        type: 'ranking',
        userid: user.userid,
        teamid: user.teamid,
      }),
    );
    console.log(sWs);
  }, [sWs]);

  const sendId = useCallback(() => {
    sWs.current?.send(
      JSON.stringify({
        action: 'onMessage',
        teamcategory: team.category,
        type: 'updateconnectionid',
        userid: user.userid,
        teamid: user.teamid,
      }),
    );
  }, [user.userid, user.teamid, question.hint]);

  const reOpenConnection = useCallback(() => {
    console.log('reabriu a conexao');
    sWs.current = new WebSocket(ENDPOINT_WS);
    sWs.current.onopen = (event) => {
      sendId();
    };
  }, [ENDPOINT_WS, sendId]);

  const validatePong = useCallback(() => {
    console.log('terminou a função ping');
  }, []);

  // const sendMessage = useCallback(
  //   (userName: string, teamId: string, message: string) => {
  //     if (userName !== '' && teamId !== '' && message !== '') {
  //       sWs.current?.send(
  //         JSON.stringify({
  //           action: 'onMessage',
  //           type: 'chat',
  //           teamId,
  //           userName,
  //           message,
  //         }),
  //       );
  //     }
  //   },
  //   [sWs],
  // );

  const getCurrentQuestionByTeamId = useCallback(async () => {
    Axios.get<Question>(
      `${ENDPOINT}/team/official/currentquestion?teamid=${user.teamid}&userid=${user.userid}`,
    ).then((response) => {
      if (response.data.type === 'end') {
        push('/endgame');
      }

      console.log('puxo');

      setQuestion(response.data);
      console.log(response.data);
      setQId(response.data.questionid);

      console.log('puxo2');

      setCaracterCounter(parseInt(response.data.answercharactercounter, 10));
      console.log('puxo3');
      /*if(response.data.hint !== " " && refreshTip) {
        addToast({
          title: 'Dica',
          description: response.data.hint,
          type: 'success',
        });
        setRefreshTip(!refreshTip);
        console.log(refreshTip);
      }
      /*if(response.data.hint !== " ") {
        addToast({
          title: 'Dica',
          description: response.data.hint,
          type: 'success',
        })
      };*/
    });
  }, [
    ENDPOINT,
    push,
    user.userid,
    user.teamid,
    question.hint,
    addToast,
    setQId,
  ]);

  useEffect(() => {
    sWs.current = new WebSocket(ENDPOINT_WS);
    sWs.current.onopen = (event) => {
      sendId();
      getCurrentQuestionByTeamId();
      console.log('puxo4');
      if (question.hint !== ' ' && question.hint !== '') {
        addToast({
          title: 'Dica',
          description: question.hint,
          type: 'success',
        });
      }
      setInterval(() => {
        if (sWs !== undefined) {
          ping();
        }
      }, 30000);

      if (sWs.current !== undefined) {
        getRanking();
        console.log('puxo5');
        if (sWs !== undefined) {
          sWs.current.onerror = (err) => {
            // sWs.current?.close();
            // reOpenConnection();
            console.log('deu merda');
            window.location.reload();
          };
        }
        sWs.current.onmessage = (e) => {
          try {
            if (e.data.includes('pong')) {
              setVerifyPing(e.data);
            }

            if (e.data.includes('points')) {
              setWsResponse(e.data);
            }
            if (e.data === 'updatecurrentquestion') {
              getRanking();
              getCurrentQuestionByTeamId();
              /*if(question.hint !== " " && refreshTip) {
                addToast({
                  title: 'Dica',
                  description: question.hint,
                  type: 'success',
                });
                setRefreshTip(false);
              }*/
              addToast({
                title: 'Boa!',
                description: 'Sua equipe acertou a resposta',
                type: 'success',
              });
            }
            if (e.data === 'updatecurrentquestionhint') {
              getCurrentQuestionByTeamId();
              /*addToast({
                title: 'Dicaaiaia',
                description: question.hint,
                type: 'success',
              });*/
            }
            if (e.data.includes('message')) {
              const recievedMessage: Message = JSON.parse(e.data);
              addMessage(recievedMessage.message, recievedMessage.userName);
              console.log(e.data);
            }
            if (e.data.includes('refreshranking')) {
              console.log('refresh foi chamado');
            }
            if (e.data === 'updatecurrentquestionpassed') {
              getRanking();
              getCurrentQuestionByTeamId();
              console.log('ta pulano memo');
              addToast({
                title: 'Alerta',
                description: 'Sua equipe pulou a questão',
                type: 'info',
              });
            }
          } catch {
            window.location.reload();
            console.log('err');
          }
        };
      }
    };

    console.log('puxo6');

    return () => {
      sWs.current?.close();
    };
  }, [
    ENDPOINT_WS,
    addMessage,
    addToast,
    clearMessages,
    getCurrentQuestionByTeamId,
    getRanking,
    ping,
    reOpenConnection,
    sWs,
    sendId,
    user,
    team.currentquestionid,
    user.userid,
    user.teamid,
    question.hint,
    validatePong,
    verifyPing,
  ]);

  const handleShowConfirmTip = useCallback(() => {
    if (question.hint !== ' ') {
      setConfirmTip(false);
      handleShowHint();
    } else if (question.hint === ' ') {
      setConfirmTip(true);
    }
  }, [confirmTip, question.hint, setConfirmTip]);

  const handleShowHint = useCallback(() => {
    setConfirmTip(false);
    console.log(qId);
    console.log(question.hint);

    Axios.get<hint>(
      `${ENDPOINT}/question/official/hint?questionid=${question.questionid}&teamid=${user.teamid}&userid=${user.userid}`,
    ).then((response) => {
      if (question.hint == '') {
        addToast({
          title: 'Alerta',
          description: 'Essa questão não possui nenhuma dica disponível.',
          type: 'info',
        });
      }
      console.log(response.data);
      sWs.current?.send(
        JSON.stringify({
          action: 'onMessage',
          type: 'updatecurrentquestionhint',
          userid: user.userid,
          teamid: user.teamid,
          teamcategory: team.category,
        }),
      );
      sWs.current?.send(
        JSON.stringify({
          action: 'onMessage',
          type: 'refreshranking',
          teamcategory: team.category,
        }),
      );
    });
    //getCurrentQuestionByTeamId();
  }, [addToast, question.hint, confirmTip, qId]);

  const handleShowConfirm = useCallback(() => {
    setConfirm(true);
  }, [confirm]);

  const handlePassQuestion = useCallback(() => {
    setConfirm(!confirm);
    Axios.get<nextquestion>(
      `${ENDPOINT}/question/official/pass?questionid=${question.questionid}&teamid=${user.teamid}&userid=${user.userid}`,
    ).then((response) => {
      try {
        setCaracterCounter(999);
        setRememberAnswer('');
        if (response.data.nextQuestion.questionid) {
          setIsPassing(false);
          setConfirm(!confirm);
          setQuestion(response.data.nextQuestion);
          console.log('vai pula');
          sWs.current?.send(
            JSON.stringify({
              action: 'onMessage',
              type: 'updatecurrentquestionpassed',
              userid: user.userid,
              teamid: user.teamid,
              teamcategory: team.category,
            }),
          );
          sWs.current?.send(
            JSON.stringify({
              action: 'onMessage',
              type: 'refreshranking',
              teamcategory: team.category,
            }),
          );
        }
        console.log(response.data);
        console.log(response.data.nextQuestion);
        console.log(response.data.nextQuestion.questionid);
        if (response.data.nextQuestion.questionid == undefined) {
          setIsPassing(false);
          addToast({
            title: 'Calma',
            description: 'Aguarde o tempo limite para pular a questao. (30s)',
            type: 'info',
          });
        }
      } catch {
        /*window.location.reload();*/
        console.log('oia o pulo');
      }
    });
  }, [ENDPOINT, confirm, question.questionid, user.userid, user.teamid]);

  const handleAnswer = useCallback(
    async (data: DataFormInfo) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          answer: Yup.string().required(
            'Escreva algo na resposta ou pule a pergunta',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setIsAnswering(true);

        Axios.post<AnswerQuestion>(`${ENDPOINT}/question/official/answer`, {
          userid: user.userid,
          teamid: user.teamid,
          questionid: question.questionid,
          questionanswer: data.answer,
        }).then((response) => {
          if (response.data.iscorrect) {
            setCaracterCounter(999);
            setRememberAnswer('');
            console.log(response.data);
            /*addToast({
              title: 'Boa!',
              description: 'Sua equipe acertou a resposta',
              type: 'success',
            });*/

            formRef.current?.clearField('answer');
            setQuestion(response.data.nextquestion);

            sWs.current?.send(
              JSON.stringify({
                action: 'onMessage',
                type: 'updatecurrentquestion',
                userid: user.userid,
                teamid: user.teamid,
                teamcategory: team.category,
              }),
            );
            sWs.current?.send(
              JSON.stringify({
                action: 'onMessage',
                type: 'refreshranking',
                teamcategory: team.category,
              }),
            );
          } else {
            addToast({
              title: 'Erro',
              description: 'Resposta incorreta',
              type: 'error',
            });
          }
          setIsAnswering(false);
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [ENDPOINT, addToast, question.questionid, user.userid, user.teamid],
  );

  const handleReportError = useCallback(() => {
    setReportError(!reportError);
  }, [reportError]);

  const handleConfirm = useCallback(() => {
    setConfirm(!confirm);
  }, [confirm]);

  const handleConfirmTip = useCallback(() => {
    setConfirmTip(!confirmTip);
  }, [confirmTip]);

  const handleYesButton = useCallback(() => {
    setIsPassing(!passing);
    handlePassQuestion();
  }, [handlePassQuestion, passing]);

  const handleYesButtonTip = useCallback(() => {
    handleShowHint();
  }, [handleShowHint]);

  const handleCaracterChange = useCallback(
    (e) => {
      setRememberAnswer(e.target.value);
      const counter: String = e.target.value;
      const questionCounter = parseInt(question.answercharactercounter, 10);
      setCaracterCounter(questionCounter - counter.length);
    },
    [question.answercharactercounter],
  );

  return (
    <S.PageContent>
      {reportError && (
        <Alert
          QuestionId={question.questionid}
          title="Reportar erro"
          buttonTitle="Enviar"
          placeholder="Digite o que está acontecendo aqui"
          show={reportError}
          errFunc={handleReportError}
        />
      )}
      {confirm && (
        <Confirm
          title="Deseja mesmo pular a questão?"
          closeFunc={handleConfirm}
          show={confirm}
          pass={handleYesButton}
        />
      )}
      {confirmTip && (
        <Confirm
          title="Deseja mesmo pedir uma dica? A pontuação da questão cairá pela metade"
          closeFunc={handleConfirmTip}
          show={confirmTip}
          pass={handleYesButtonTip}
        />
      )}
      <Header>
        <S.LogoutButton onClick={signOut}>
          <FiLogOut size={20} />
          Sair
        </S.LogoutButton>
      </Header>
      <S.Container className="console">
        {question.type !== 'final' && (
          <S.FirstRowContainer>
            <S.SideContainer className="sidecontainer" ref={rankingRef}>
              <Ranking
                content={
                  wsResponse !== '' &&
                  wsResponse !== 'pong' &&
                  JSON.parse(wsResponse)
                }
              >
                <br />
              </Ranking>

              <S.ReportErrorButton
                className="report-error-button"
                onClick={handleReportError}
              >
                <S.ReportErrorImage className="report-error-image" src={erro} />
              </S.ReportErrorButton>
            </S.SideContainer>
            <S.QuestionContainer
              className="question-container"
              ref={consoleRef}
            >
              <S.QuestionOverlay>
                <S.Question className="question">
                  <S.QuestionHeader
                    className="question-header"
                    normal={question.type === 'normal'}
                  >
                    <S.NumberQuestion className="question-number">
                      <h1 style={{ color: '#FFFFFF' }}>
                        {`${question.questionid}`}
                      </h1>
                    </S.NumberQuestion>
                    <p
                      className="question-title"
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {`${question.title.replaceAll('<br/>', '\n')}`}
                    </p>
                  </S.QuestionHeader>
                  {question.type !== 'normal' && (
                    <S.QuestionContentContainer className="question-content-container">
                      <QuestionContent
                        className="question-content"
                        type={question.type}
                        url={question.url}
                      />
                    </S.QuestionContentContainer>
                  )}
                </S.Question>
                {!answering && !passing ? (
                  <>
                    <S.Answer className="question-answer-container">
                      <Form
                        style={{ width: '100%' }}
                        ref={formRef}
                        onSubmit={handleAnswer}
                      >
                        <S.FormContent>
                          <S.AnswerInput
                            onChange={(e) => handleCaracterChange(e)}
                            name="answer"
                            placeholder="Digite a resposta aqui"
                            defaultValue={rememberAnswer}
                          />
                          <S.AnswerButton type="submit">
                            <FiPlay size={20} />
                          </S.AnswerButton>
                        </S.FormContent>
                      </Form>
                    </S.Answer>
                    <S.Hint className="question-hint">
                      {caracterCounter > 1 &&
                        question.title !== '' &&
                        caracterCounter < 999 && (
                          <>
                            Faltam <strong>{caracterCounter}</strong> caracteres
                            em sua resposta
                          </>
                        )}

                      {caracterCounter === 1 && caracterCounter < 999 && (
                        <>
                          Falta <strong>{caracterCounter}</strong> caractere em
                          sua resposta
                        </>
                      )}

                      {caracterCounter === 0 && caracterCounter < 999 && (
                        <>
                          Falta <strong>{caracterCounter}</strong> caractere em
                          sua resposta
                        </>
                      )}

                      {caracterCounter < 0 && caracterCounter < 999 && (
                        <strong>Sua resposta excedeu os caracteres</strong>
                      )}
                    </S.Hint>
                  </>
                ) : (
                  <ReactLoading color="#000" type="balls" />
                )}
              </S.QuestionOverlay>
              <S.ButtonsDiv
                className="question-action-buttons"
                ref={actionButtonsRef}
              >
                <S.SkipButton onClick={handleShowConfirm} />
                {/*question.hint !== ' ' && !passing && (
                      
                    )*/}
                <S.HintButton onClick={handleShowConfirmTip}>
                  <S.TipButton />
                </S.HintButton>
              </S.ButtonsDiv>
            </S.QuestionContainer>
          </S.FirstRowContainer>
        )}
        {question.type === 'final' && (
          <>
            <FinalComponent text={question.title}>
              <Ranking
                content={
                  wsResponse !== '' &&
                  wsResponse !== 'pong' &&
                  JSON.parse(wsResponse)
                }
              >
                <br />
              </Ranking>
            </FinalComponent>
          </>
        )}

        {/* <SecondRowContainer enabled={false}>
          <VideoCards>
            <VideoCard></VideoCard>
            <VideoCard></VideoCard>
            <VideoCard></VideoCard>
            <VideoCard></VideoCard>
            <VideoCard></VideoCard>
            <VideoCard></VideoCard>
          </VideoCards>
          <ChatContainer>
            <Chat sendMessage={sendMessage} wsConnection={sWs.current} />
          </ChatContainer>
              </SecondRowContainer> */}
      </S.Container>
    </S.PageContent>
  );
};

export default Questionary;
