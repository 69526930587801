import { keyframes } from 'styled-components';

export const EarnPoints = keyframes`
 from{
   opacity: 0;
 }
 to{
  opacity: 1;
 }
`;
