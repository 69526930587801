import styled, { css } from 'styled-components';
import { EarnPoints } from './animations';
import iconranking from '../../assets/img/iconeranking.svg';

interface ItemProps {
  myTeam: boolean;
}

export const Container = styled.div`
  width: 90%;
  height: 85%;
  margin-left: -3%;

  color: #000;
  font-family: 'Poppins';

  padding: 8px;
  overflow: hidden;

  overflow: hidden-scroll;

  @media (max-width: 700px) {
    padding: 0px;
  }

  @media (max-width: 1100px) {
    width: 250px;
  }
`;

export const Content = styled.div`
  display: inline-block;
  position: relative;

  border-radius: 12px;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageBackground = styled.img`
  width: 100%;
  @media (max-width: 1150px) {
    width: 250px;
  }
`;

export const Header = styled.div`
  top: 1.2rem;
  // margin-top: 2%;
  margin-left: 65px;
  color: #e10051;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  border-radius: 12px;
`;

export const Icon = styled.div`
  background-image: url(${iconranking});
  display: flex;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-right: 3%;

  width: 20px;
  height: 20px;
`;

export const Title = styled.h1`
  font-family: 'Kumbh Sans';
  font-weight: 600;
  font-size: 20px;

  @media (max-width: 700px) {
    font-size: 12px;
  }
`;

export const Row = styled.div`
  flex-direction: row;
  display: flex;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  gap: 8px;
`;

export const Body = styled.ul`
  position: absolute;

  top: 3rem;
  left: 4rem;

  justify-content: center;
  align-items: flex-start;

  width: 70%;
  max-height: 80%;
  margin-right: -1%;

  // display: flex !important;
  flex-direction: column;
  //border-radius: 12px;

  // padding: 8px;

  // padding-top: 4rem;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const Item = styled.span<ItemProps>`
  // margin-left: 13%;
  margin-bottom: 1.3%;
  min-width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //margin-left: 1.7rem;

  // margin-right: auto;
  //padding: 8px;

  & + div {
    margin-top: 10px;
  }

  border-radius: 12px;

  img {
    width: 50px;
    height: 50px;
  }

  strong {
    color: #999999;
    font-size: 10px;
  }

  h4 {
    margin-right: auto;
    font-size: 13px;
    color: #ffffff;
    padding-left: 4px;
    //display: contents;
    text-overflow: ellipsis;

    ${(props) =>
      props.myTeam &&
      css`
        width: 80% !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `}
  }

  transition: transform 0.8s;

  ${(props) =>
    props.myTeam &&
    css`
      animation: ${EarnPoints} 1s;
    `};

  &:hover {
    /* cursor: pointer; */
    transform: scaleY(1.09) scaleX(1.09);
  }

  p {
  }
  @media (max-width: 1150px) {
    height: 24%;
    margin-left: 1rem;
  }

  @media (max-width: 700px) {
    flex-direction: column;

    height: 20%;
  }
  @media (max-height: 640px) {
    height: 23%;
  }
`;

export const QuestionPoints = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: 4%;
  justify-content: space-between;
  //width: 80%;
  strong {
    font-size: 11px;
    width: 100%;
    margin-left: 28px;
  }

  @media (max-width: 1100px) {
    strong {
      font-size: 10px;
    }
  }
`;

interface PositionLabelProps {
  myTeam: boolean;
}
export const PositionLabel = styled.h3<PositionLabelProps>`
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;

  justify-content: center;
  align-items: center;
  display: flex;

  ${(props) =>
    props.myTeam &&
    css`
      width: 20px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background: rgb(225, 0, 81);
      border-radius: 210px;
      width: 20px !important;
      height: 20px;
    `};

  ${(props) =>
    !props.myTeam &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 0.4rem;
    `}

  @media (max-width: 750px) {
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      overflow: visible;
    }
  }
`;
