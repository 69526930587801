/* eslint-disable react/jsx-indent */
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import {
  PageRegulamento,
  RulesContainer,
  Card,
  Content,
  Title,
  SubContent,
  SubContentItem,
  StyledButton,
  Rules,
  CardContent,
  ButtonsContainer,
} from './styles';
import Header from '../../components/Header';
import Json from './regulamento.json';

interface SubItem {
  text: string;
  id: number;
}

const Faq: React.FC = () => {
  const refContainer = useRef<HTMLInputElement>(null);

  const scrollDownContainer = () => {
    if (refContainer !== null && refContainer && refContainer.current) {
      refContainer?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//code.jivosite.com/widget/OKHPleczFN';
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <PageRegulamento>
      <Header />
      <RulesContainer>
        <Content>
          <h2 style={{ marginBottom: '10px' }}>FAQ</h2>
          <p>Perguntas frequentes:</p>
          <Rules>
            {Json.regulamento.map((item) => {
              return (
                <Card key={item.title}>
                  <Title>{item.title}</Title>
                  <CardContent>{item.content}</CardContent>
                  <SubContent>
                    {item.subcontents.map(({ id, text }: SubItem) => {
                      return <SubContentItem key={id}>{text}</SubContentItem>;
                    })}
                  </SubContent>
                </Card>
              );
            })}
            <div ref={refContainer}></div>
            <ButtonsContainer>
              <Link to="/" style={{ textDecoration: ' none' }}>
                <StyledButton style={{ backgroundColor: '#1e70c3' }}>
                  Participe já
                </StyledButton>
              </Link>
            </ButtonsContainer>
          </Rules>
        </Content>
        <ButtonsContainer>
          <StyledButton onClick={() => scrollDownContainer()}>
            Rolar até o final
          </StyledButton>
        </ButtonsContainer>
      </RulesContainer>
    </PageRegulamento>
  );
};

export default Faq;
