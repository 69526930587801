import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { DefaultColors } from '../../styles/global';

import { loadFromTransparent, bringFromLeft } from './animations';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Input from '../../components/Input';
import paymentConsole from '../../assets/img/paymentConsole.png';
import cardConsole from '../../assets/img/cardConsole.png';

interface Test {
  load?: boolean;
}

interface StyledButtonProps {
  countdownOver?: boolean;
}

interface CircleContainerProps {
  payment: string;
}

export const PageGame = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const TContainer = styled.div`
  width: 100vw;
  height: 87.9vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  

  padding: 18px;

  animation: ${loadFromTransparent} 1.4s;
`;

export const PageWrapper = styled.div`
  display: flex;
`;

export const CircleContent = styled(Card)<CircleContainerProps>`
  width: 500px;
  height: 500px;

  display: flex;
  justify-content: center;
  position: relative;

  @media (max-width: 820px) {
    box-sizing: border-box;
    background-image: none;
    background-color: white;
    border-radius: 10px;
    width: 90%;
    max-width: 350px;
    height: auto;
    max-height: 500px;
    margin: 0 auto !important;
    margin-bottom: 100px;
    z-index: 9999999;
    flex-direction: column;
    
    border: 20px solid transparent;
    background: linear-gradient(to left, ${DefaultColors.primaryColor}, ${DefaultColors.primaryColor});

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -8px;
      border-radius: inherit;
      background: #fff;
    }
  }

  ${(props) =>
    props.payment === 'card'
      && css`
          background-image: url(${cardConsole});
          width: 797px;
          height: 539px;
        `}

  ${(props) =>
    props.payment === 'pix'
      && css`
          background-image: url(${cardConsole});
          width: 797px;
          height: 539px;
        `}

  ${(props) =>
    props.payment !== 'card' && props.payment !== 'pix'
      && css`
        background-image: url(${paymentConsole});
        background-position-y: 5.5rem !important;
      `}
 
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  padding: 0 38px;
  margin: 0 auto;

  animation: ${bringFromLeft} 1s;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  margin-top: 12%;

  text-align: center;

  a {
    font-family: 'Poppins';
    text-decoration: none;

    transition: color 0.3s;

    &:hover {
      color: ${shade(0.4, '#fff')};
    }
  }
`;

export const Content = styled.p`
  width: 440px;

  text-align: center;
  margin-top: 2em;

  font-family: 'Poppins';
  font-size: 18px;

`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }

  @media (max-width: 820px) {
    padding: 0;
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

export const BackButton = styled(Button)<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7%;

  background: #fb7c1f;
  color: #fff;

  &:hover {
    background: ${shade(0.4, '#fb7c1f')};
  }

  font-family: 'Poppins';
  font-size: 18px;

  ${(props) =>
    props.countdownOver &&
    css`
      width: 100%;
    `}
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;
  border-radius: 220px;

  color: #fff;

  font-family: 'Poppins';
  font-size: 14px;
  

  ${(props) =>
    props.countdownOver ? 
    css`
    background: ${DefaultColors.secondColor};
    &:hover {
      background: ${shade(0.4, DefaultColors.secondColor)};
    }
    ` :
    css`
    background: ${DefaultColors.primaryColor};
    &:hover {
      background: ${shade(0.4, DefaultColors.primaryColor)};
    }
    `}
`;



export const StyledInput = styled(Input)`
  /* width: 300px; */
`;

export const Logo = styled.img`
  height: 40px;
  position: absolute;

  opacity: 1;
`;

export const LogoContent = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  align-items: left;
  justify-content: left;
`;

export const LogoOptions = styled.div`
  display: flex;
  flex-direction: row;

  margin-left: auto;

  a {
    text-decoration: none;
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;

    transition: color 0.4s;

    font-family: 'Poppins';
    font-size: 14px;

    @media (max-width: 820px) {
      margin: 0 0 0 0;
      text-align: center;
      font-weight: 600;
    }
  }

  .amount {
    width: 100%;
    text-align: center;

    @media (max-width: 820px) {
      width: 100%;
    }
  }
`;

export const GridCustom = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;

  div > button {
    margin: 0;
    width: 90%;
  }

  @media (max-width: 820px) {
    grid-template-columns:  1fr;
    gap: 1rem;

    > div {
      display: flex;
      justify-content: center;
    }
  }
`;
