import React, { useState, useEffect } from 'react';

import { useAuth } from '../../hooks/auth';
import { useTeam } from '../../hooks/team';
import ranking from '../../assets/img/Ranking.png';

import {
  Container,
  Header,
  Title,
  Content,
  Body,
  Item,
  QuestionPoints,
  Icon,
  Row,
  ImageBackground,
  PositionLabel,
} from './styles';

interface RankingProps {
  content?: any;
}

interface Ranking {
  category: string;
  currentquestionid?: string;
  founder: string;
  imageurl: string;
  name: string;
  points: number;
  teamid?: string;
}

const Ranking: React.FC<RankingProps> = ({ children, content }) => {
  const { user } = useAuth();
  const { team } = useTeam();

  const [list, setList] = useState<Ranking[]>([]);

  useEffect(() => {
    if (content !== false) {
      setList(content);
    }
  }, [content, user]);

  return (
    <Container className="ranking-content-container">
      <Content>
        <Header>
          <Icon />
          <Title>Ranking</Title>
        </Header>
        <Body className="ranking-body">
          {list.map((item, index) => (
            <Item
              className="ranking-item"
              myTeam={user.teamid === item.teamid}
              key={item.teamid}
            >
              <QuestionPoints>
                <Row>
                  <PositionLabel
                    className="position-label"
                    myTeam={user.teamid === item.teamid}
                  >{`${index + 1}°`}</PositionLabel>
                  <h4>{`${item.name}`}</h4>
                </Row>
                {item.currentquestionid === '11' && (
                  <strong>Finalizado</strong>
                )}
                {item.currentquestionid !== '11' && (
                  <strong className="current-question-id">{`Questão ${item.currentquestionid}`}</strong>
                )}
              </QuestionPoints>
              {/*user.teamid === item.teamid && (
                <p>{`${item.points} pts`}</p>
              )*/}
            </Item>
          ))}
        </Body>
        <ImageBackground src={ranking} />
      </Content>
    </Container>
  );
};

export default Ranking;
