import React from 'react';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { ChatProvider } from './chat';
import { TeamProvider } from './team';
import { PayProvider } from './payment';

const AppProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <PayProvider>
        <TeamProvider>
          <ChatProvider>
            <ToastProvider>{children}</ToastProvider>
          </ChatProvider>
        </TeamProvider>
      </PayProvider>
    </AuthProvider>
  );
};

export default AppProvider;
