import styled, { keyframes, css } from 'styled-components';
import { shade } from 'polished';
import confirm from '../../assets/img/modalVideo.png';
import { DefaultColors } from '../../styles/global';

import Button from '../Button';

interface AlertProps {
  show?: boolean;
}

interface CustomButtonProps {
  bg?: string;
}

const loadFromOpacity = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const Container = styled.div<AlertProps>`
  position: absolute;

  width: 100vw;
  height: 100vh;
  z-index: 999999;
  overflow: hidden;

  ${(props) =>
    props.show &&
    css`
      animation: ${loadFromOpacity} 0.6s;
    `}

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 0.7);
  z-index: 100;

  font-family: 'Poppins';
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${confirm});
  background-repeat: no-repeat;
  background-size: 700px;
  background-position: 50% 50%;
  z-index: 10000;
  margin-top: 3%;

  height: 680px;

  @media (max-width: 820px) {
    box-sizing: border-box;
    background-image: none;
    background-color: white;
    border-radius: 10px;
    width: 90%;
    max-width: 350px;
    height: auto;
    margin: 0 auto !important;
    margin-bottom: 100px;
    position: fixed;
    top: 8rem;
    left: 0;
    right: 0;
    z-index: 9999999;

    border: 20px solid transparent;
    background: linear-gradient(
      to left,
      ${DefaultColors.primaryColor},
      ${DefaultColors.primaryColor}
    );

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -8px;
      border-radius: inherit;
      background: #fff;
    }
  }
`;

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 700px;
  height: auto;

  padding: 32px;

  z-index: 100000;
`;

export const AlertHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 67%;
  margin-top: -3%;

  margin-bottom: 10px;
`;

export const Title = styled.h1`
  font-size: 20px;
  color: #61393a;
  text-align: center;
`;

export const Description = styled.div`
  margin-top: auto;

  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 60%;
`;

export const StyledButton = styled(Button)<CustomButtonProps>`
  width: 120px;
  height: 40px;
  border-radius: 210px;

  ${(props) =>
    props.bg
      ? css`
          background: ${DefaultColors.secondColor};
          &:hover {
            background: ${shade('0.4', DefaultColors.secondColor)};
          }
        `
      : css`
          background: ${DefaultColors.secondColor};
          &:hover {
            background: ${shade('0.4', DefaultColors.secondColor)};
          }
        `}
`;

export const CloseButton = styled.div`
  margin-left: auto;
  margin-bottom: auto;

  svg {
    color: #c53030;
    transition: color 0.4s;
  }

  &:hover {
    cursor: pointer;
    svg {
      color: ${shade('0.4', DefaultColors.secondColor)};
    }
  }
`;
