import React, { useCallback, useEffect } from 'react';
import { FiXCircle } from 'react-icons/fi';

import {
  Container,
  Content,
  AlertContainer,
  AlertHeader,
  CloseButton,
  Description,
  StyledButton,
  Title,
} from './styles';

interface ShowVideoProps {
  show?: boolean;
  closeFunc?(): void;
  title?: string;
  pass?(): void;
}

const ShowVideo: React.FC<ShowVideoProps> = ({
  show = false,
  closeFunc,
  title,
  pass,
}) => {
  const handleYesButton = useCallback(() => {
    pass && pass();
  }, [pass]);
  const handleCancelButton = useCallback(() => {
    closeFunc && closeFunc();
  }, [closeFunc]);

  return (
    <Container show={show}>
      <Content>
        <AlertContainer>
          <AlertHeader>
            <Title>
              <p>{title}</p>
            </Title>
          </AlertHeader>

          <div
            style={{
              background: '#000',
              width: '100%',
              maxWidth: '560px',
              height: '315px',
            }}
          >
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/lcT6YgOwT-4"
              title="Challenge"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <Description>
            {/* <StyledButton onClick={handleYesButton}>Sim</StyledButton> */}
            <StyledButton onClick={handleCancelButton} bg="fb7c1f">
              Entendi
            </StyledButton>
          </Description>
        </AlertContainer>
      </Content>
    </Container>
  );
};

export default ShowVideo;
