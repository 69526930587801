import React, { useCallback } from 'react';

import ReactAudioPlayer from 'react-audio-player';
import { FiDownload } from 'react-icons/fi';
import { Container, StyledVimeo, ImageContainer } from './styles';

interface ContentProps {
  type?: string;
  url?: string;
  className?: string;
}

const QuestionContent: React.FC<ContentProps> = ({ type, url, className }) => {
  const showCurrentTime = useCallback((info: any) => {}, []);

  const handleEndVideo = useCallback((info: any) => {}, []);

  const handleProgressVideo = useCallback((info: any) => {}, []);

  const handleOpenImage = useCallback(() => {
    window.open(`${url}`);
  }, [url]);

  return (
    <Container className={className}>
      {type === 'video' && (
        <StyledVimeo
          className={`${className}-video`}
          video={url || ' '}
          onPause={(info) => showCurrentTime(info)}
          onEnd={(info) => handleEndVideo(info)}
          onTimeUpdate={(info) => handleProgressVideo(info)}
          start={0}
          style={{
            width: '60%',
          }}
          responsive
        />
      )}
      {type === 'audio' && (
        <>
          <ReactAudioPlayer
            className={`${className}-audio`}
            src={url}
            controls
            volume={0.5}
          />
        </>
      )}
      {type === 'file' && (
        <a
          className={`${className}-download`}
          rel="noopener noreferrer"
          target="_blank"
          href={`https://${url}`}
        >
          <FiDownload size={30} />
          Baixar o arquivo
        </a>
      )}
      {type === 'image' && (
        <ImageContainer
          className={`${className}-image`}
          onClick={handleOpenImage}
          imageUrl={url}
        />
      )}
    </Container>
  );
};

export default QuestionContent;
