import { createGlobalStyle } from 'styled-components';
import rocketBg from '../assets/img/bgfiltered.png';
import pricedown from '../assets/fonts/Pricedown.ttf';
import styled, { css } from 'styled-components';

export const DefaultColors = {
  primaryColor: '#F37021',
  secondColor: '#8D45ED',
  thirdColor: '#53129A',
  backGrayLightColor: '#f3f3f3'
}

export default createGlobalStyle`
  @font-face {
    font-family: Pricedown;
    src: url(${pricedown});
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    font-family: 'Archivo';

    display:flex;
    justify-content: center;
    align-items: center;
    padding: 32px;

    background-image: url(${rocketBg});

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  html,body{
    height: 100%;
    margin: 0px;
  }

  html {
    @media (min-width: 980px) {
      overflow-x: hidden;
  }

  .MuiPaper-root {
    cursor: pointer;

    option {
      cursor: pointer;
    }
  }

  body::-webkit-scrollbar {
    width: 1em;
  }
  
  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: ${DefaultColors.secondColor};
    outline: 1px solid ${DefaultColors.secondColor};
  }

  .MuiList-root {
    padding: .7rem !important;

    option {
      margin: 0 0 5px 0 !important;
    }
  }

  .container-checkbox {
    display: block;
    position: relative;
    padding-left: 0;
    cursor: pointer;
    font-size: 11px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #eee;
      border-radius: 2px;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
      background-color: #2196F3;
    }
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    /* Show the checkmark when checked */
     input:checked ~ .checkmark:after {
      display: block;
    }
  
    /* Style the checkmark/indicator */
     .checkmark:after {
      left: 9px;
      top: 5px;
      width: 2px;
      height: 6px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`;

export const StyledButtonBack = styled.button`
  height: 40px;
  z-index: 999;

  position: relative;
  top: 15px;
  cursor: pointer;

  opacity: 1;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  align-self: flex-end;
  border-radius: 200px;
  font-size: 18px;
  margin-bottom: 15px;
  padding: 0 5px;
  z-index: 999;
  width: 90px;
  height: 40px;
  background: rgb(255, 255, 255);
  color: rgb(23, 143, 235);
  margin-left: 1em;
  justify-content: space-around;
  border-color: transparent;
`;
