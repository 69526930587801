import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useAuth } from '../../hooks/auth';
import { useTeam } from '../../hooks/team';
import { useToast } from '../../hooks/toast';

import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import {
  A,
  PageGame,
  TContainer,
  PageWrapper,
  CircleContent,
  FormContainer,
  P,
  StyledButton,
  Users,
  UserContainer,
  Buttons,
  ImageComponents,
  ImageContainer,
  MainImg,
  StyledInput,
  DefaultListItem,
} from './styles';
import Axios from 'axios';
import {
  FiCheck,
  FiDollarSign,
  FiUserPlus,
  FiX,
  FiPlay,
  FiEdit,
  FiSave,
} from 'react-icons/fi';
import Header from '../../components/Header';
import Confirm from '../../components/Confirm';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import ImageUpload from '../../components/ImageUpload';
import * as Yup from 'yup';

import getValidationErrors from '../../utils/getValidationErrors';
import { ChallengeText } from '../Landing/styles';
import { FaWindows } from 'react-icons/fa';

const initialInputValues: any = {
  name: '',
  founder: '',
  currentquestionid: '',
  imageurl: '',
  category: '',
  points: '',
  teamid: '',
  paymentStatus: '',
  gameStatus: '',
};

const Main: React.FC = () => {
  const { user } = useAuth();
  const { team, updateTeam } = useTeam();
  const { addToast } = useToast();

  const [membros, setMembros] = useState<any[]>([]);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [gameStatus, setGameStatus] = useState('false');
  const [pendentes, setPendentes] = useState<any[]>([]);
  const [isLogging, setIsLogging] = useState(true);
  const [confirmQ, setConfirmQ] = useState(false);
  const [teamImgUrl, setTeamImgUrl] = useState('');
  const [toggleEdit, setToggleEdit] = useState(false);
  const [inputValues, setInputValues] = useState(initialInputValues);
  const formRef = useRef<FormHandles>(null);

  const iconStyle = {
    marginLeft: '-10px',
    marginRight: '10px',
  } as React.CSSProperties;

  const defaultUserProfileImage = useMemo<string>(
    () =>
      'https://nextlevelimagesprofile.s3-sa-east-1.amazonaws.com/defaultUser.png',
    [],
  );

  const defaultTeamProfileImage = useMemo<string>(
    () => 'https://cdn.raceroster.com/assets/images/team-placeholder.png',
    [],
  );

  const handleConfirm = useCallback(() => {
    if (gameStatus === 'off') {
      addToast({
        type: 'info',
        title: 'Acalma coração',
        description: 'O evento ainda não foi liberado.',
      });
    } else {
      setConfirmQ(!confirmQ);
    }
  }, [confirmQ, team, gameStatus]);

  const handleYesButton = useCallback(() => {
    window.location.href = '/questionary';
  }, [window.location.href]);

  const handleEditTeam = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome completo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await Axios.post(
          `https://j1hjd787mc.execute-api.sa-east-1.amazonaws.com/prod/team/editinfo`,
          {
            teamid: team.teamid,
            teamimgurl: teamImgUrl.split('base64,')[1],
            teamname: data.name,
          },
        ).then((res) => {
          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Informações salvas com sucesso!',
          });

          let updateUserParam = {
            category: res.data.category,
            currentquestionid: res.data.currentquestionid,
            founder: res.data.founder,
            imageurl: res.data.imageurl,
            name: res.data.name,
            points: res.data.points,
            teamid: res.data.teamid,
            paymentStatus: team.paymentStatus,
            gameStatus: team.gameStatus,
          };

          updateTeam(updateUserParam);
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops...',
          description: 'Algo deu errado, tente novamente mais tarde.',
        });

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      } finally {
        setToggleEdit(false);
      }
    },
    [teamImgUrl, team, inputValues],
  );

  const carrega = useCallback(async () => {
    setIsLogging(true);

    await Axios.get(
      `https://j1hjd787mc.execute-api.sa-east-1.amazonaws.com/prod/team?teamid=${team.teamid}`,
    ).then((response) => {
      setPaymentStatus(response.data.paymentStatus);
    });

    await Axios.get(
      `https://j1hjd787mc.execute-api.sa-east-1.amazonaws.com/prod/team?teamid=${team.teamid}`,
    ).then((response) => {
      setGameStatus(response.data.gameStatus);
    });

    await Axios.get(
      `https://j1hjd787mc.execute-api.sa-east-1.amazonaws.com/prod/team?teamid=${team.teamid}`,
    ).then((response) => {
      setMembros(response.data.users);
    });

    await Axios.get(
      `https://j1hjd787mc.execute-api.sa-east-1.amazonaws.com/prod/team?teamid=${team.teamid}`,
    ).then((response) => {
      setPendentes(response.data.pendingusers);
    });

    await Axios.get(
      `https://j1hjd787mc.execute-api.sa-east-1.amazonaws.com/prod/team?teamid=${team.teamid}`,
    ).then((response) => {
      setGameStatus(response.data.gameStatus)
    });

    setIsLogging(false);
  }, [team, paymentStatus]);

  const handleInputChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  useEffect(() => {
    carrega();

    const script = document.createElement('script');

    script.src = '//code.jivosite.com/widget/OKHPleczFN';
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [carrega]);

  useEffect(() => {
    setInputValues({
      ...initialInputValues,
      name: team.name,
      founder: team.founder,
      currentquestionid: team.currentquestionid,
      imageurl: team.imageurl,
      category: team.category,
      points: team.points,
      teamid: team.teamid,
      paymentStatus: team.paymentStatus,
      gameStatus: team.gameStatus,
    });
  }, [team]);

  return (
    <PageGame>
      {confirmQ && (
        <Confirm
          title="Você está prestes a entrar no questionário oficial. Deseja continuar?"
          closeFunc={handleConfirm}
          show={confirmQ}
          pass={handleYesButton}
        />
      )}
      <Header />
      <script src="//code.jivosite.com/widget/OKHPleczFN" async />
      {user && (
        <TContainer>
          <PageWrapper style={{ margin: '0 auto' }}>
            <FormContainer>
              <CircleContent>
                <Form ref={formRef} onSubmit={handleEditTeam} className="form">
                  <P>Gerencie o seu time</P>
                  <div className="teamInfo">
                    {toggleEdit ? (
                      <>
                        <ImageComponents>
                          <ImageContainer>
                            <div className="main-profile-img">
                              <MainImg
                                src={
                                  teamImgUrl &&
                                  teamImgUrl !== ' ' &&
                                  teamImgUrl !== undefined
                                    ? teamImgUrl
                                    : team.imageurl !== ' '
                                    ? team.imageurl
                                    : defaultTeamProfileImage
                                }
                              />
                            </div>
                          </ImageContainer>
                          <div className="imageUploader">
                            <ImageUpload setImgUrl={setTeamImgUrl} />
                          </div>
                        </ImageComponents>
                        <StyledInput
                          name="name"
                          placeholder="Nome da Equipe"
                          value={inputValues.name}
                          onChange={(e) => handleInputChanges(e)}
                        />
                        <label htmlFor="submit_form">
                          <FiSave />
                        </label>
                        <input
                          id="submit_form"
                          name="submit_form"
                          type="submit"
                          value=""
                          hidden
                        />
                      </>
                    ) : (
                      <>
                        <ImageContainer>
                          <div className="main-profile-img">
                            <MainImg
                              src={
                                teamImgUrl &&
                                teamImgUrl !== ' ' &&
                                teamImgUrl !== undefined
                                  ? teamImgUrl
                                  : team.imageurl !== ' '
                                  ? team.imageurl
                                  : defaultTeamProfileImage
                              }
                              alt="team image"
                            />
                          </div>
                        </ImageContainer>
                        <ChallengeText>
                          {team.name}
                          <FiEdit onClick={() => setToggleEdit(!toggleEdit)} />
                        </ChallengeText>
                      </>
                    )}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    Categoria: {team.category}
                  </div>
                  <Buttons>
                    <Link to="/invite">
                      <StyledButton type="submit">
                        {' '}
                        <FiUserPlus /> Adicionar Membros
                      </StyledButton>
                    </Link>
                    {isLogging && (
                      <StyledButton enabled={false}>
                        <ReactLoading />
                      </StyledButton>
                    )}
                    {!isLogging && paymentStatus !== 'PAID' && (
                      <Link to="/payment">
                        <StyledButton enabled>
                          {' '}
                          <FiDollarSign style={iconStyle} /> Pagar inscrição
                        </StyledButton>
                      </Link>
                    )}
                    {!isLogging && paymentStatus === 'PAID' && (
                      <div>
                        <StyledButton
                          //className="blocked"
                          onClick={handleConfirm}
                        >
                          {' '}
                          <FiPlay /> Responder Quiz Oficial{' '}
                        </StyledButton>
                        <StyledButton enabled={false}>
                          {' '}
                          <FiCheck style={iconStyle} />
                          Inscrição Paga
                        </StyledButton>
                      </div>
                    )}
                  </Buttons>
                </Form>
                <Users>
                  <P>Membros do time:</P>
                  <UserContainer>
                    {membros.map((users, index) => (
                      <DefaultListItem key={index} list-style-type="none">
                        <ImageComponents>
                          <ImageContainer>
                            <div className="main-profile-img">
                              <MainImg
                                src={
                                  users.imageurl !== ' '
                                    ? users.imageurl
                                    : defaultUserProfileImage
                                }
                              />
                            </div>
                          </ImageContainer>
                        </ImageComponents>
                        <A>
                          <p>
                            {JSON.stringify(users.fullname).replace(/"/g, '')}
                          </p>
                          <div className="accepted">
                            <FiCheck /> aceito
                          </div>
                        </A>
                      </DefaultListItem>
                    ))}
                    {pendentes.map((users, index) => (
                      <DefaultListItem key={index} list-style-type="none">
                        <ImageComponents>
                          <ImageContainer>
                            <div className="main-profile-img">
                              <MainImg src={defaultUserProfileImage} />
                            </div>
                          </ImageContainer>
                        </ImageComponents>
                        <A>
                          <p>{JSON.stringify(users).replace(/"/g, '')}</p>
                          <div className="pending">
                            <FiX /> pendente
                          </div>
                        </A>
                      </DefaultListItem>
                    ))}
                  </UserContainer>
                  <br />
                </Users>
              </CircleContent>
            </FormContainer>
          </PageWrapper>
        </TContainer>
      )}
      {!user && team && <TContainer>{(window.location.href = '/')}</TContainer>}
    </PageGame>
  );
};

export default Main;
