import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { DefaultColors } from '../../styles/global'

import { loadFromTransparent, bringFromLeft } from './animations';

import Button from '../../components/Button';
import Card from '../../components/Card';

import SubscribeBackground from '../../assets/img/subscribeForm.png';


interface Test {
  load?: boolean;
}

interface ImgProps {
  isRight?: boolean;
}

interface StyledButtonProps {
  countdownOver?: boolean;
}

export const PageGame = styled.div`
  width: 100vw;
  min-height: 100vh;
`;

export const FlexRow = styled.div`
  display: grid;
  flex-direction: row;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  margin-bottom: 10px;

  @media (max-width: 820px) {
    grid-template-columns: 1fr;
  }
`;

export const FlexColumn = styled.div`
  display: grid;
  flex-direction: column;
  grid-template-columns: 1fr;
  gap: 5px;
`;

export const FormHeaderTitle = styled.h2`
  width: 100%;
  text-align: center;
  margin-bottom: 16px;

  @media (max-width: 820px) {
    margin-bottom: 5px;
    font-size: 1rem;
  }  
`;

export const FormHeader = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 16px;

  @media (max-width: 820px) {
    margin-bottom: 5px;
    font-size: .8rem;
  }  
`;

export const TContainer = styled.div`
  width: 90vw;
  height: 0vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 18px;

  /* animation: ${loadFromTransparent} 1.4s; */

  @media (max-width: 820px) {
    width: inherit;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
`;

export const CircleContent = styled(Card) <Test>`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  position: fixed;

  ${(props) =>
    props.load
      ? css`
          /* animation: ${bringFromLeft} 1s; */
        `
      : css`
          /* animation: ${bringFromLeft} 1s; */
        `}
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 1500px;
  height: 1500px;

  background-image: url(${SubscribeBackground});
  background-repeat: no-repeat;
  background-size: auto;
  background-position-x: center;
  background-position-y: center;
  text-align: center;
  margin-left: -265px;

  @media (max-width: 820px) {
    box-sizing: border-box;
    background-image: none;
    background-color: white;
    border-radius: 10px;
    width: 90%;
    max-width: 350px;
    height: auto;
    margin: 0 auto !important;
    margin-bottom: 100px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999999;
    
    border: 20px solid transparent;
    background: linear-gradient(to left, ${DefaultColors.secondColor}, ${DefaultColors.secondColor});

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -8px;
      border-radius: inherit;
      background: #fff;
    }
  }

  a {
    font-family: 'Poppins';
    text-decoration: none;
    color: #fff;

    transition: color 0.3s;

    &:hover {
      color: ${shade(0.4, '#fff')};
    }
  }

  .macroForm {
    display: flex;
    width: 100%;
    height: 90vh;
    margin-top: 32px;
    padding: 0px 100px;
    margin-bottom: 10%;
    justify-content: space-around;
    align-content: center;
    align-items: center;

    @media (max-height: 635px) {
      /* padding: 0px 314px; */
    }

    @media (max-width: 820px) {
      flex-direction: column;
      height: auto;
      padding: 0;
      max-width: 350px;
      margin: 0;
    }
  }

  .leftForm {
    /* padding-bottom: 8%; */

    div[data-testid] {
      width: 100%;
      overflow: hidden;

      svg {
        display: none !important;
      }

      div > svg {
        display: none !important;
      }

      @media (max-width: 820px) {
        width: 100%;
      }
    }
    
    /* @media (max-height: 835px) { */
      padding-bottom: 5%;
      margin-left: 115px;
      width: 29%;
      margin-top: 155px;
    /* } */

    @media (max-width: 820px) {
      margin: 0 !important;
      width: 100%;
    }
  }

  .rightForm {
    display: "flex";
    flex-direction: "column";
    max-width: 300px;

    div[data-testid] {
      svg {
        display: none !important;
      }

      div > svg {
        display: none !important;
      }

      @media (max-width: 820px) {
        width: 100%;
      }
    }

    /* @media (max-height: 835px) { */
      margin-top: 165px;
      margin-right: 165px;
    /* } */

    @media (max-width: 820px) {
      margin: 0 !important;
      width: 100%;
      max-width: 100%;
    }
  }

  .select {
    width: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  a {
    text-decoration: none;
  }
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 200px;
  margin-top: 0;

  width: 40%;
  height: 30px;

  background: ${DefaultColors.secondColor};
  color: #fff;

  font-family: 'Poppins';
  font-size: 14px;

  &:hover {
    background: ${shade(0.4, DefaultColors.secondColor)};
  }
  
  @media (max-height: 835px) {
    /* height: 30px; */
  }
`;

export const ImageContainer = styled.div<ImgProps>`
  position: relative;
  display: flex;

  flex-shrink: 0;
  /* margin-left: 165px; */

  .main-profile-img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: solid 2px #dbdbdb;

    @media (max-height: 835px) {
      width: 50px;
      height: 50px;
    }
  }
  @media (max-height: 835px) {
    /* margin-left: 120px; */
  
    ${(props) =>
      props.isRight
        && css`
        /* margin-left: 90px; */
    `}
  }

  ${(props) =>
    props.isRight
      && css`
      /* margin-left: 120px; */
  `}
`;

export const ImageComponents = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  /* height: 22%; */

  margin-bottom: 2%;

  .imageUploader {
    display: flex;
    position:absolute;
    top: 22%;
    left: 66%;
  }
`;

export const MainImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
`;

export const LContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  align-items: center;
  align: center;
  justify-content: left;

  margin-left: 22em;
  margin-top: 2em;
`;

export const SelectC = styled.div`
  background: #fff;
  border-radius: 10px;

  display: flex;
  align-items: center;

  padding: 16px;
  width: 111%;
  height: 56px;
  margin-left: 4px;
  margin-right: 40px;

  border: 2px solid rgb(219,219,219);
  color: #666360;

  padding: 7px;
  width: 85%;
  height: 32px;
  transition: border 0.3s;
  overflow: hidden;

  .MuiInput-root {
    border:none !important;
    border-bottom: none !important;

    :before, :hover, :after {
      border:none !important;
      border-bottom: none !important;
    }

    option {
      cursor: pointer;
    }
  }

  & + div {
    margin-top: 8px;
  }

  input {
    background: transparent;
    flex: 1;
    border: 0;

    color: #000;
    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }

  @media (max-height: 835px) {
    /* height: 35px; */
    width: 86%;
  }
  
  @media (max-width: 835px) {
    /* height: 35px; */
    width: 100%;
  }
`;

export const LogoContent = styled.div`
  width: 100%;
  height: 40px;
  z-index: 999;

  display: flex;
  align-items: left;
  justify-content: left;
`;

export const LogoOptions = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 999;


  margin-left: auto;

  a {
    text-decoration: none;
  }

  p {
    margin-right: 48px;

    transition: color 0.4s;

    font-family: 'Poppins';
    font-size: 18px;

    &:hover {
      cursor: pointer;
      color: ${shade(0.4, '#bdbdbd')};
    }
  }

  @media (max-width: 820px) {
    margin-left: 20px;
  }  
`;

export const StyledButtonBack = styled.button`
  height: 40px;
  z-index: 999;

  position: relative;
  top: 15px;
  cursor: pointer;

  opacity: 1;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  align-self: flex-end;
  border-radius: 200px;
  font-size: 18px;
  margin-bottom: 15px;
  padding: 0 5px;
  z-index: 999;
  width: 90px;
  height: 40px;
  background: rgb(255, 255, 255);
  color: rgb(23, 143, 235);
  margin-left: 1em;
  justify-content: space-around;
  border-color: transparent;

  @media (max-width: 820px) {
    display: none;
    width: inherit;
    margin-left: 35px;
    top: -60px;
  }
`;