import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { loadFromTransparent, bringFromLeft } from './animations';
import FormBackground from '../../assets/img/imageForm.png';
import { DefaultColors } from '../../styles/global';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Input from '../../components/Input';

interface Test {
  load?: boolean;
}

interface StyledButtonProps {
  countdownOver?: boolean;
  isGreen?: boolean;
}

export const PageGame = styled.div`
  width: 100vw;
  min-height: 100vh;

  .loading {
    margin-top: 25vh;
    margin-left: 25vw;
  }
`;

export const TContainer = styled.div`
  width: 90vw;
  height: 0vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 18px;

  /* animation: ${loadFromTransparent} 1.4s; */
`;

export const PageWrapper = styled.div`
  display: flex;
`;

export const CircleContent = styled(Card) <Test>`
  width: 100vw;
  height: 73vh;

  display: flex;
  justify-content: center;
  position: fixed;
  margin-top: 15px;

  ${(props) =>
    props.load
      ? css`
          /* animation: ${bringFromLeft} 1s; */
        `
      : css`
          /* animation: ${bringFromLeft} 1s; */
        `}
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 95%;

  background-image: url(${FormBackground});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%, 50%;
  text-align: center;
  padding: 0px 55px;

  @media (max-width: 820px) {
    box-sizing: border-box;
    background-image: none;
    background-color: white;
    border-radius: 10px;
    width: 90%;
    max-width: 350px;
    height: auto;
    margin: 0 auto !important;
    margin-bottom: 100px;
    position: fixed;
    top: 30%;
    left: 0;
    right: 0;
    z-index: 9999999;
    
    border: 20px solid transparent;
    background: linear-gradient(to left, #0076ce, #0075cd);

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -8px;
      border-radius: inherit;
      background: #fff;
    }

    div > form {
      margin: 0 auto !important;
      width: 65% !important;
    }
  }

  a {
    font-family: 'Poppins';
    text-decoration: none;
    color: #fff;

    transition: color 0.3s;

    &:hover {
      color: ${shade(0.4, '#fff')};
    }
  }

  div[data-testid] {
      width: 100%;
      overflow: hidden;

      svg {
        display: none !important;
      }

      div > svg {
        display: none !important;
      }
    }
`;

export const FormHeaderTitle = styled.h2`
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
`;

export const Margin = styled.div`
  margin-left: 5%;
`;

export const Content = styled.p`
  width: 440px;

  text-align: center;
  margin-top: 2em;

  font-family: 'Poppins';
  font-size: 18px;

  color: #fff;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 820px) {
    flex-direction: column;
  }

  a {
    text-decoration: none;
  }
`;

export const FormHeader = styled.div`
  width: 100%;
  padding-left: 3%;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 16px;
`;

export const BackButton = styled(Button)<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7%;

  background: #fb7c1f;
  color: #fff;

  &:hover {
    background: ${shade(0.4, '#fb7c1f')};
  }

  font-family: 'Poppins';
  font-size: 18px;
`;

export const StyledButton = styled(Button) <StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 200px;

  width: 40%;
  height: 30px;

  background: ${DefaultColors.secondColor};
  color: #fff;

  font-family: 'Poppins';
  font-size: 14px;

  &:hover {
    background: ${shade(0.4, DefaultColors.secondColor)};
  }

  @media (max-width: 820px) {
    width: 80%;
  }

  ${(props) =>
    !props.isGreen
      ? css`
        background: ${DefaultColors.secondColor};
        `
      : css`
        background: ${DefaultColors.secondColor};
  `}
`;

export const StyledInput = styled(Input)`
   
`;

export const Logo = styled.img`
  height: 40px;
  width: 40px;

  opacity: 1;
`;

export const LogoContent = styled.div`
  width: 10%;
  height: 370px;

  display: flex;
  align-items: left;
  justify-content: left;
  position: relative;
`;

export const LogoOptions = styled.div`
  display: flex;
  flex-direction: row;

  margin-left: auto;
`;

export const LContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  align-itens: center;
  align: center;
  justify-content: center;

  margin-left: 20vw;
  margin-top: 0vh;
`;

