import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FiLock, FiUser, FiUserCheck, FiUserPlus } from 'react-icons/fi';
import * as Yup from 'yup';
import ReactLoading from 'react-loading';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import getValidationErrors from '../../utils/getValidationErrors';

import {
  PageGame,
  TContainer,
  CircleContent,
  StyledButton,
  PageWrapper,
  FormContainer,
  FormHeaderTitle,
  StyledInput,
  LContainer,
  ButtonsContainer,
  FormHeader,
  Margin,
} from './styles';

import Header from '../../components/Header';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

interface DataFormInfo {
  password: string;
  email: string;
}

const Password: React.FC = () => {
  const { addToast } = useToast();
  const { requestPasswordEmail, resetPassword } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const currentlyUrl = window.location;
  const params = new URLSearchParams(currentlyUrl.search);

  const handleLostPassword = useCallback(async (data: DataFormInfo) => {
    setIsSending(true);
    if (currentlyUrl.pathname.includes('forgot')) {
      console.log(data);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string().required('Email obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await requestPasswordEmail(data.email);

        if (response.data === 'OK') {
          addToast({
            title: 'Feito!',
            description: 'Email enviado com sucesso!',
            type: 'success',
          });
        } else if (response.data === 'User not found') {
          addToast({
            title: 'Oops!',
            description:
              'Não encontramos um usuário com essa identificação, verifique seu email e tente novamente!',
            type: 'error',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            title: 'Oops!',
            description:
              'Oops... parece que algo deu errado, confira seu email!',
            type: 'error',
          });
        }
      }
    } else {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().required('Senha obrigatória!'),
        });

        if (!currentlyUrl.search.includes('token')) {
          addToast({
            title: 'Atenção',
            description:
              'Você necessita de um token válido para cadastrar uma nova senha!',
            type: 'info',
          });
          setIsSending(false);

          return;
        }

        await schema.validate(data, {
          abortEarly: false,
        });

        let paramToken = params.get('token');

        const response = await resetPassword(paramToken, data.password);

        if (response.data === 'OK') {
          addToast({
            title: 'Sucesso',
            description:
              'Você já pode utilizar sua nova senha na tela de login!',
            type: 'success',
          });

          setTimeout(() => (window.location.href = '/'), 5000);
        } else if (response.data === 'token not found') {
          addToast({
            title: 'Falha na verificação',
            description:
              'Verificamos que o link de verificação já foi utilizado anteriormente, faça uma nova solicitação de email e tente novamente!',
            type: 'error',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            title: 'Erro!',
            description:
              'Oops... parece que algo deu errado, tente novamente mais tarde!',
            type: 'error',
          });
        }
      }
    }
    setIsSending(false);
    formRef.current?.reset();
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//code.jivosite.com/widget/OKHPleczFN';
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <PageGame>
      <Header />
      {loading && (
        <LContainer>
          <ReactLoading
            type="spin"
            color="orange"
            height={100}
            width={190}
            className="loading"
          />
        </LContainer>
      )}
      <script src="//code.jivosite.com/widget/OKHPleczFN" async />
      {!loading && (
        <TContainer>
          <PageWrapper>
            <CircleContent>
              {currentlyUrl.pathname.includes('forgot') ? (
                <FormContainer>
                  <Margin>
                    <Form
                      ref={formRef}
                      onSubmit={handleLostPassword}
                      style={{
                        width: '98%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <FormHeaderTitle>Redefinir Senha</FormHeaderTitle>
                      <FormHeader>Digite seu email</FormHeader>
                      <StyledInput
                        name="email"
                        icon={FiUser}
                        placeholder="Email"
                        style={{ width: 300 }}
                      />

                      <ButtonsContainer>
                        <StyledButton
                          style={{ margin: '0 auto' }}
                          enabled
                          type="submit"
                        >
                          {isSending ? (
                            <>
                              <ReactLoading />
                            </>
                          ) : (
                            <>Enviar</>
                          )}
                        </StyledButton>
                      </ButtonsContainer>
                    </Form>
                  </Margin>
                </FormContainer>
              ) : (
                <FormContainer>
                  <Margin>
                    <Form
                      ref={formRef}
                      onSubmit={handleLostPassword}
                      style={{
                        width: '98%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <FormHeaderTitle>Redefinir Senha</FormHeaderTitle>
                      <FormHeader>Digite sua nova senha</FormHeader>
                      <StyledInput
                        className="hidden_icon"
                        name="password"
                        icon={FiUser}
                        placeholder="Nova senha"
                        style={{ width: 300 }}
                      />

                      <ButtonsContainer>
                        <StyledButton
                          style={{ margin: '0 auto' }}
                          enabled
                          type="submit"
                        >
                          {isSending ? (
                            <>
                              <>
                                <ReactLoading />
                              </>
                            </>
                          ) : (
                            <>Enviar</>
                          )}
                        </StyledButton>
                      </ButtonsContainer>
                    </Form>
                  </Margin>
                </FormContainer>
              )}
            </CircleContent>
          </PageWrapper>
        </TContainer>
      )}
    </PageGame>
  );
};

export default Password;
